import { UserModel } from 'src/app/shared/models/user.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Subscription, merge, of, fromEvent, Subject } from 'rxjs';
import { catchError, map, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { select, Store } from '@ngrx/store';
import { AppState, selectUserState } from './core/core.state';
import { GraphqlService } from './core/graphql/graphql.service';
import { removeUser } from './core/user/user.actions';
import { authLogout } from './core/auth/auth.actions';
import { selectIsAuthenticated } from './core/auth/auth.selectors';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'umanweb-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'UmanWeb';
  private componentDestroyed$: Subject<void> = new Subject<void>();
  networkStatus: boolean = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  connectionLost: boolean = false;
  timer: NodeJS.Timeout;
  user: UserModel;
  version = environment.appVersion;
  assetsPath = environment.assetspath;
  get fullname() {
    return `${this.user?.firstname} ${this.user?.lastname}`
  }

  constructor(
    private toastCtrl: ToastController,
    private _store: Store<AppState>,
    private _graphql: GraphqlService,
    private _translate: TranslateService
  ) {


    this._store.pipe(
      takeUntil(this.componentDestroyed$),
      select(selectUserState),
    ).subscribe(user => this.user = user)
    this._store.pipe(
      select(selectIsAuthenticated),
      takeUntil(this.componentDestroyed$),
    )
      .subscribe(isAuth => {
        if (isAuth) {
          this._graphql.refreshToken()
            .pipe(take(1),
              catchError(error => of(this.logOut())))
            .subscribe(({ data }: any) => {
              //we take half of the expiration time, so 1000/2 = 500
              this.timer = setInterval(this.refreshToken, 500 * (data.refreshToken.payload.exp - data.refreshToken.payload.origIat))
            });
        }
        else {
          if (this.timer) {
            clearInterval(this.timer)
          }
        }
      })
  }

  ngOnInit(): void {
    this.checkNetworkStatus();
  }
  //countdown in seconds
  refreshToken = () => {
    this._graphql.refreshToken().pipe(take(1))
      .pipe(catchError(error => of(this.logOut())))
      .subscribe((_) => {
      });
  }

  async makeToast(header: string, message: string, color: string) {
    const toast = await this.toastCtrl.create({
      color,
      header,
      message,
      duration: 8000,
      position: 'bottom',
    });
    toast.present();
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
    this.componentDestroyed$.next()
    this.componentDestroyed$.complete()
  }

  logOut() {
    this._store.dispatch(authLogout());
    this._store.dispatch(removeUser());
  }

  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(async (isOnline: any) => {
        if (!isOnline) {
          this.makeToast(
            await this._translate.get("connexionLost").toPromise(),
            await this._translate.get("connexionLostDetails").toPromise(),
            'danger'
          );
          this.connectionLost = true;
        } else if (isOnline && this.connectionLost) {
          this.makeToast(await this._translate.get("connexionBack").toPromise(), '', 'success');
          this.connectionLost = false;
        }
        this.networkStatus = isOnline;
      });
  }
}
