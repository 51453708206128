import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../core.state';
import { selectIsAuthenticated } from './auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  implements OnDestroy {
  isAuthenticated: boolean;
  authSubscription: Subscription;
  constructor(private _store: Store<AppState>,  private router: Router) {
    this.authSubscription = this._store.pipe(select(selectIsAuthenticated)).subscribe(isAuth => { this.isAuthenticated = isAuth})
  }

  canActivate(): boolean {
    if(this.isAuthenticated){
      return true;
    }
    else{
      this.router.navigateByUrl('/login');
      return false;
    }
  }

  ngOnDestroy(){
    this.authSubscription.unsubscribe()
  }
}
