import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(public auth: AuthGuardService, private store: Store<any>,
        private _cookies: CookieService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(this._cookies.get("csrftoken")){
            request = request.clone({
                setHeaders: {
                    'X-CSRFToken' : this._cookies.get("csrftoken"),
                }
            });
        }
        return next.handle(request);
    }
}