import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  isSmallScreen = new BehaviorSubject<boolean>(false);
  constructor(
    private toastCtrl: ToastController,
    public breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(['(min-width: 900px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isSmallScreen.next(false);
        } else {
          this.isSmallScreen.next(true);
        }
      });
  }

  async makeToast(header: string, message: string, color: string) {
    const toast = await this.toastCtrl.create({
      color,
      header,
      message,
      duration: 2000,
      position: 'bottom',
    });
    toast.present();
  }

  determineMinMaxAge(age):[min: number, max: number] {
    if (age > 0 && age <= 36) {
      return [0,36]
    } else if (age > 36 && age <= 96) {
      return [36, 96]
    } else if (age > 96 && age <= 144) {
      return [96,144]
    } else if (age > 144 && age <= 216) {
      return [144, 216]
    } else {
      return [216, 348]
    }
  }

  getAgeFromBirthdate(birthday){
    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    age = age * 12 + m;
    return age;
  }

  getDefaultMinMaxFromBirthday(birthday):[min: number, max: number] {
    return this.determineMinMaxAge(
      this.getAgeFromBirthdate(birthday)
    );

  }
}
