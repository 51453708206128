import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState, selectUserState } from '../core/core.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class RegularizePaymentGuard  {
  constructor(private _store: Store<AppState>, private router: Router) {
  }

  canActivateChild():Observable<boolean> {
    
    return this._store.pipe(
      select(selectUserState),
      map(user =>{
        if (user.paymentStatus=="normal" || user.paymentStatus=="trial" || user?.subscription?.status == 'active') {
          return true;
        }
        else {
          this.router.navigateByUrl('/regularizePayment');
          return false;
        }
      })
    )
  }

  canDeactivate() {
    return true;
  }
}
