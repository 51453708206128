import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() title: string;
  @Input() color: string;
  @Input() icon: string;
  @Input() isParagraph: boolean;
  @Input() isDisabled: boolean;
  @Input() paragraph: string;
  @Input() line1: string;
  @Input() line2: string;
  @Input() line3: string;
  @Input() line4: string;
  @Input() line5: string;
  @Input() loading = false;
  @Input() button1: string;
  @Input() button2: string;
  @Input() buttonIcon = "arrow_forward";
  @Input() button1disabled = false;
  @Input() button3: string;
  @Input() iconOnly: boolean;

  @Output() button1Clicked = new EventEmitter();

  @Output() button2Clicked = new EventEmitter();
  constructor() {}

  onB1Clicked() {
    this.button1Clicked.emit();
  }

  onB2Clicked() {
    this.button2Clicked.emit();
  }
}
