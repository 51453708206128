<div style="padding: 24px;">
  <h1 mat-dialog-title>{{ data.title }}</h1>
<p mat-dialog-content>{{ data.msg }}</p>
<div class="scrollable">
  <div *ngIf="data.learningEarly.length > 0">
    <p style="color: #55c40c;">{{ "learningEarlyCount" | translate }}</p>
    <ul>
      <li *ngFor="let ques of data.learningEarly">{{ 'QUESTIONS.'+ques.answerCode + '_name' | translate }}</li>
    </ul>
  </div>
  <div *ngIf="data.learningLate.length > 0">
    <p style="color: #b51c1c;">{{ "learningLateCount" | translate }}</p>
    <ul>
      <li *ngFor="let ques of data.learningLate">{{ 'QUESTIONS.'+ques.answerCode + '_name' | translate }}</li>
    </ul>
  </div>
</div>

  <div mat-dialog-actions style="
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
">
    <button mat-button [mat-dialog-close]="true" (click)="confirm()" cdkFocusInitial>
      {{ "confirm" | translate | capitalizeFirstLetter }}
    </button>
    <button mat-button mat-dialog-close (click)="cancel()">{{ "cancel" | translate | capitalizeFirstLetter }}</button>
  </div>
</div>