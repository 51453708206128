import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core/core.state';
import { selectIsPasswordReset } from '../core/user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class ChangePasswordGuard  {
  passwordReset: boolean;
  _storeSubscription: Subscription;
  constructor(private _store: Store<AppState>, private router: Router) {
    this._storeSubscription = this._store.pipe(select(selectIsPasswordReset)).subscribe(passwordReset => { this.passwordReset = passwordReset })
  }

  canActivate(): boolean {
    
    if (this.passwordReset) {
      return true;
    }
    else {
      this.router.navigateByUrl('/changePassword');
      return false;
    }
  }

  canDeactivate() {
    this._storeSubscription.unsubscribe()
    return true;
  }
}
