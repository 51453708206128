import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import fr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { IonicModule } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {  HttpClientModule } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  } from './umanweb-superuser/superuser-panel.component';
import { CoreModule } from './core';
import { SharedModule } from './shared/shared.module';


registerLocaleData(fr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    FormsModule, ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    IonicModule.forRoot(),
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    CoreModule,

    SharedModule,
     IonicModule.forRoot({})

  ],
  providers:[],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
