import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangePasswordGuard } from './change-password/change-password.guard';
import { AuthGuardUmanwebSuperuser } from './umanweb-superuser/umanweb-superuser.guard';
import { RegularizePaymentGuard } from './regularize-payment/regularize-payment.guard';
import { AuthGuardService } from './core/auth/auth-guard.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'start' },
  {
    path: 'start',
    loadChildren: () =>
      import('./home/home.module').then(
        (m) => m.HomeModule
      ),
    canActivate: [AuthGuardService, ChangePasswordGuard],
    canActivateChild: [RegularizePaymentGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'tos',
    loadChildren: () => import('./tos/tos.module').then((m) => m.TosModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./privacy/privacy.module').then((m) => m.PrivacyModule),
  },
  {
    path: 'changePassword',
    loadChildren: () =>
      import('./change-password/change-password.module').then((m) => m.ChangePasswordModule),
      canActivate: [AuthGuardService]
  },
  {
    path: 'resetForgottenPassword/:uidb64/:token',
    loadChildren: () =>
      import('./reset-forgotten-password/reset-forgotten-password.module').then((m) => m.ResetForgottenPasswordModule),
  },
  {
    path: 'superuserPanel',
    loadChildren: () =>
      import('./umanweb-superuser/umanweb-superuser.module').then((m) => m.UmanwebSuperuserModule),
      canActivate: [AuthGuardUmanwebSuperuser]
  },
  {
    path: 'regularizePayment',
    loadChildren: () =>
      import('./regularize-payment/regularize-payment.module').then((m) => m.RegularizePaymentModule),
      canActivate: [AuthGuardService,ChangePasswordGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
