<ion-card [style.border]="color ? '4px solid' + color : '4px solid'" [disabled]="isDisabled" class="card">
  <ion-progress-bar *ngIf="loading" type="indeterminate"></ion-progress-bar>

  <div>
    <ion-card-title class="card-header">{{ title | translate | capitalizeFirstLetter }}</ion-card-title>
      <div *ngIf="iconOnly"  class="card-icon only-icon" >
        <mat-icon class="" color="accent">{{ icon }}</mat-icon>
      </div>
    <div *ngIf="!iconOnly">
        <mat-icon class="card-icon" color="accent" style="font-size: 42px; height: 42px; margin-top: 20px;">{{ icon }}</mat-icon>
      <div>
        <div class="info" style="color: #333333" *ngIf="!isParagraph && line1">
          <h3>{{ line1 }}</h3>
        </div>
        <div class="info" style="color: #333333" *ngIf="!isParagraph && line2">
          <h3>{{ line2 }}</h3>
        </div>
        <div class="info" style="color: #333333" *ngIf="!isParagraph && line3">
          <h3>{{ line3 }}</h3>
        </div>
        <div class="info" style="color: #333333" *ngIf="!isParagraph && line4">
          <h3>{{ line4 }}</h3>
        </div>
        <div class="info" style="color: #333333" *ngIf="!isParagraph && line5">
          <h3>{{ line5 }}</h3>
        </div>
      </div>
      <div *ngIf="isParagraph">
        <div class="info" style="
            color: #333333;
            margin: 15px;
            white-space: pre-line;
            text-align: center;
          ">
          <h3>{{ paragraph | translate | capitalizeFirstLetter}}</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="card-buttons">
    <button *ngIf="button1" mat-flat-button color="accent" [disabled]="button1disabled" class="card-button"
      (click)="onB1Clicked()">
      {{ button1 | translate | capitalizeFirstLetter }}
      <mat-icon iconPositionEnd>{{ buttonIcon }}</mat-icon>
    </button>
    <button *ngIf="button2" mat-flat-button color="accent" class="card-button" (click)="onB2Clicked()">
      {{ button2 | translate | capitalizeFirstLetter }}
      <mat-icon iconPositionEnd>arrow_forward</mat-icon>
    </button>
  </div>
</ion-card>