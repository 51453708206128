import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tuto',
  templateUrl: './tuto.component.html',
  styleUrls: ['./tuto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutoComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
