import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppState } from 'src/app/core/core.state';
import { selectIsSuperUser } from 'src/app/core/user/user.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class FooterComponent implements OnDestroy {
  version = environment.appVersion;
  storeSub: Subscription;
  isSuperuser = false;
  constructor(
    private _store: Store<AppState>,
    ) {
      this.storeSub = this._store.pipe(
        select(selectIsSuperUser),
        take(1),
      ).subscribe(isSuperuser => {
        this.isSuperuser = isSuperuser;
      })
  }
  ngOnDestroy(): void {
    this.storeSub.unsubscribe()
  }
}
