<div class="footer">
  © Unidéveloppement Sàrl |
  <a routerLink="/privacy" target="_blank" rel="noopener">{{ "confidentiality" | translate | capitalizeFirstLetter }}</a>
  |
  <a routerLink="/tos" target="_blank" rel="noopener">{{ "terms" | translate | capitalizeFirstLetter }}</a>
  <span *ngIf="isSuperuser">
    |
    <a routerLink="/superuserPanel">admin</a>
  </span>
  | Version: {{ version }}
</div>