import { createReducer, on, Action } from "@ngrx/store";
import { UserModel } from "src/app/shared/models/user.model";
import { removeUser, setSubscription, setUser, togglePasswordReset } from "./user.actions";

export const initialState: UserModel = undefined;


const reducer = createReducer(
    initialState,
    on(setUser, (state, {user}) => ({ ...state, ...user })),
    on(setSubscription, (state, {subscription}) => ({...state, subscription: {...subscription}})),
    on(togglePasswordReset, state => ({...state, passwordReset: !state.passwordReset})),
    on(removeUser, state => (undefined)),
  );
  
  export function userReducer(
    state: UserModel,
    action: Action
  ): UserModel {
    return reducer(state, action);
  }
  
