import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector
} from '@ngrx/store';

import { environment } from '../../environments/environment';

import { initStateFromLocalStorage } from './meta-reducers/init-state-from-local-storage.reducer';
import { debug } from './meta-reducers/debug.reducer';
import { AuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { userReducer } from './user/user.reducer';
import { UserModel } from '../shared/models/user.model';
import { recordReducer } from './record/record.reducer';
import { RecordState } from './record/record.model';

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  user: userReducer,
  record: recordReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

if (!environment.production) {
    metaReducers.unshift(debug);
}

export const selectAuthState = createFeatureSelector(
  'auth',
);

export const selectUserState = createFeatureSelector<UserModel>(
  'user',
);

export const selectRecordState = createFeatureSelector(
  'record',
);


export interface AppState {
  auth: AuthState;
  user: UserModel;
  record: RecordState;
}
