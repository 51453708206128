import { Component, Inject } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  cancel() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: false });
  }

  confirm() {
    // closing itself and sending data to parent component
    this.dialogRef.close({ data: true });
  }
}
