
import { TranslateLoader } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GraphqlService } from 'src/app/core/graphql/graphql.service';

export class TranslateServerLoader implements TranslateLoader {
    constructor(
        private http: HttpClient,
        private _graphql: GraphqlService) {

    }

    getTranslation(langCountry: string): Observable<any> {
        return this.http.get(environment.assetspath + '/lang/' + langCountry + '.json');
    }
}
