import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core/core.state';
import { selectIsSuperUser } from '../core/user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardUmanwebSuperuser  {
  isSuperuser: boolean;
  private _storeSubscription: Subscription;
  constructor(private _store: Store<AppState>,  private router: Router) {
    this._storeSubscription = this._store.pipe(select(selectIsSuperUser)).subscribe(isSuperuser => { this.isSuperuser = isSuperuser})
  }

  canActivate(): boolean {
    if(this.isSuperuser){
      return true;
    }
    else{
      this.router.navigateByUrl('/dashboard');
      return false;
    }
  }

  canDeactivate(){
    this._storeSubscription.unsubscribe()
    return true;
  }
}
