<mat-toolbar style="
    align-items: center;
    justify-content: space-between;
    height: 70px !important;
  ">
  <div style="width: 25%">
    <div style="cursor: pointer; max-width: 60px" routerLink="/">
      <img [src]="assetsPath + '/images/logo-UM.svg'" />
    </div>
  </div>

  <div style="width: 50%; text-align: center">
    <h1>{{ title | translate }}</h1>
    <!-- <span style="font-size: 0.85rem; margin-left: 5%; margin-top: 2px" *ngIf="subTitle">{{ subTitle }}</span> -->
  </div>
  <div style="width: 25%;">
    <mat-chip-set style="float: right" button>
      <mat-chip button [matMenuTriggerFor]="languageMenu" style="
          border-radius: 0px;
          background-color: transparent;
          cursor: pointer;
        ">
        <div>{{ translate.currentLang }}</div>
      </mat-chip>
    </mat-chip-set>
    <div *ngIf="userData">
      <mat-menu #menu="matMenu">
        <button (click)="logOut()" mat-menu-item>
          <mat-icon>logout</mat-icon>
          {{ "logout" | translate }}
        </button>
      </mat-menu>
      <mat-chip-set *ngIf="!isSmallScreen" style="float: right" tappable button>
        <mat-chip tappable button [matMenuTriggerFor]="menu" style="
          border-radius: 0px;
          background-color: transparent;
          cursor: pointer;
        ">
          <mat-icon color="accent" matChipAvatar>account_box</mat-icon>
          <div>{{ fullname }}</div>
        </mat-chip>
      </mat-chip-set>
      <div *ngIf="isSmallScreen">
        <ion-menu-button mode="ios"></ion-menu-button>
      </div>
    </div>

  </div>
  <mat-menu #languageMenu="matMenu">
    <button *ngFor="let lang of translate.getLangs()" (click)="setLanguage(lang)" mat-menu-item>
      {{ lang }}
    </button>
  </mat-menu>
</mat-toolbar>
<div *ngIf="isBack" style="
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px !important;
  ">
  <div style="width: 33%">
    <button (click)="return()" mat-button aria-label="Retourner vers la recherche de dossier"
      style="border-radius: 0px">
      <mat-icon>arrow_back</mat-icon>
      {{ backTitle | translate | capitalizeFirstLetter }}
    </button>
  </div>
  <div style="width: 33%; text-align: center">
    <h2>{{ secondTitle | translate | capitalizeFirstLetter}}</h2>
  </div>
  <div style="width: 33%"></div>
</div>