<ion-app style="position: relative; min-height: 100%">
  <ion-menu side="start" contentId="main">
    <ion-header>
      <ion-toolbar>
        <ion-title> UMANWEB </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <br />
      <div
        routerLink="/start"
        lines="none"
        style="height: 50px; margin: auto; text-align: center"
      >
        <img
          style="height: 50px; margin: auto"
          [src]="assetsPath + '/images/logo-UM.svg'"
        />
      </div>
      <br />
      <h3 style="padding: 5px; text-align: center">
        Connecté(e) en tant que <b>{{ fullname }}</b>
      </h3>
      <br />
      <div style="text-align: center">
        <ion-menu-toggle>
          <button
            style="
              height: 32px;
              border-radius: 0px;
              line-height: 0px;
              margin: 5px;
            "
            mat-flat-button
            color="accent"
            class="card-button"
            (click)="logOut()"
          >
          {{ "logout" | translate | capitalizeFirstLetter }}
            <mat-icon>logout</mat-icon>
          </button>
        </ion-menu-toggle>
      </div>

      <hr />
      <br />
      <ion-footer class="footer">
        <ion-toolbar class="footer">
          <p class="footer" style="text-align: center; color: gray">
            {{ "version" | translate | capitalizeFirstLetter }}: {{ version }}
          </p>
        </ion-toolbar>
      </ion-footer>
    </ion-content>
  </ion-menu>
  <ion-router-outlet [animated]="false" id="main"></ion-router-outlet>
</ion-app>
