import { createReducer, on, Action } from "@ngrx/store";
import { setRecord, validateObs } from "./record.actions";
import { RecordState } from "./record.model";

export const initialState: RecordState = {
  record:undefined,
  pendingObsId: null,
  validatedObsIds: []
};


const reducer = createReducer(
  initialState,
  on(setRecord, (state, recordState) => ({ ...state, ...recordState })),
  on(validateObs, (state, payload) => ({
    ...state,
    pendingObsId: null,
    validatedObsIds: [...state.validatedObsIds, payload.id]
  })),
);

export function recordReducer(
  state: RecordState,
  action: Action
): RecordState {
  return reducer(state, action);
}

