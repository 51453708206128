import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-tuto-dossier',
  templateUrl: './tuto-dossier.component.html',
  styleUrls: ['./tuto-dossier.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TutoDossierComponent {
  isStart = true;

  isEnd = false;

  slideOpts = {
    allowTouchMove: false,
    speed: 400,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    slidesPerView: 1,
  };
  constructor() {}

  slideChanged(slides) {
    slides.isEnd().then((res) => {
      this.isEnd = res;
    });
    slides.isBeginning().then((res) => {
      this.isStart = res;
    });
  }

  slidePrev(slides) {
    
    slides.slidePrev();
  }

  slideNext(slides) {
    slides.slideNext();
  }
}
