import { createSelector } from '@ngrx/store';
import { UserModel } from 'src/app/shared/models/user.model';

import { selectUserState } from '../core.state';



export const selectIsSuperUser = createSelector(
  selectUserState,
  (state: UserModel) => state?.role === "umanwebSuperuser" 
);

export const selectIsPasswordReset = createSelector(
  selectUserState,
  (state: UserModel) => state?.passwordReset 
);

export const selectPasswordReset = createSelector(
  selectUserState,
  (state: UserModel) => state?.passwordReset
);

export const selectPaymentStatus = createSelector(
  selectUserState,
  (state: UserModel) => state?.paymentStatus
);
