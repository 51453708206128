import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../core.state';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { removeUser, setUser, togglePasswordReset, setSubscription } from './user.actions';


export const USER_KEY = 'USER';

@Injectable()
export class UserEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private _store$: Store<AppState>,
    private localStorageService: LocalStorageService,
  ) {
  }

  setUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUser),
        tap(({ user }) => {
          this.localStorageService.setItem(USER_KEY, {...this.localStorageService.getItem(USER_KEY),...user});
        }
        )
      ),
    { dispatch: false }
  );

  setSubscription = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setSubscription),
        tap(({ subscription }) => {
          this.localStorageService.setItem(USER_KEY, {...this.localStorageService.getItem(USER_KEY),subscription});
        }
        )
      ),
    { dispatch: false }
  );

  removeUser = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeUser),
        tap(() => {
          this.localStorageService.removeItem(USER_KEY);
        })
      ),
    { dispatch: false }
  );

  togglePasswordReset = createEffect(
    () =>
      this.actions$.pipe(
        ofType(togglePasswordReset),
        tap(() => {
          const user = this.localStorageService.getItem(USER_KEY);
          this.localStorageService.setItem(USER_KEY, {...user,  passwordReset: !user.passwordReset});
        })
      ),
    { dispatch: false }
  );
}
