<form [formGroup]="userForm" style="max-width: 360px;">
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ 'firstname' | translate | capitalizeFirstLetter }}: </mat-label>
    <input id="firstname" matInput formControlName="firstname" />
  </mat-form-field>
  <br />
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ 'lastname' | translate | capitalizeFirstLetter }}: </mat-label>
    <input id="lastname" matInput formControlName="lastname" />
  </mat-form-field>
  <br />
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ 'etab' | translate | capitalizeFirstLetter }}: </mat-label>
    <input id="etab" matInput formControlName="etab" />
  </mat-form-field>
  <br />
  <div style="display: flex; flex-direction: row;">
    <mat-form-field appearance="outline" color="accent" style="width: 85%">
      <mat-label>{{ 'street' | translate | capitalizeFirstLetter }}: </mat-label>
      <input id="street" matInput formControlName="street" />
    </mat-form-field>
    <br />
    <mat-form-field appearance="outline" color="accent" style="width: 15%">
      <mat-label>{{ 'streetNumber' | translate | capitalizeFirstLetter }}: </mat-label>
      <input id="number" matInput formControlName="number" />
    </mat-form-field>
    <br />
  </div>
  <div style="display: flex; flex-direction: row;">
    <mat-form-field appearance="outline" color="accent" style="width: 30%">
      <mat-label>{{ 'zipCode' | translate | capitalizeFirstLetter }}: </mat-label>
      <input id="zip" matInput formControlName="zip" />
    </mat-form-field>
    <br />
    <mat-form-field appearance="outline" color="accent" style="width: 70%">
      <mat-label>{{ 'city' | translate | capitalizeFirstLetter }}: </mat-label>
      <input id="city" matInput formControlName="city" />
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ 'email' | translate | capitalizeFirstLetter }}: </mat-label>
    <input id="email" matInput formControlName="email" />
  </mat-form-field>
  <br />
  <p [innerHTML]="'passwordValidationRules' | translate"></p>
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ 'password' | translate | capitalizeFirstLetter }}: </mat-label>
    <input matInput type="password" formControlName="password" />
  </mat-form-field>
  <br />
  <mat-form-field appearance="outline" color="accent" style="width: 100%">
    <mat-label>{{ 'confirmPassword' | translate | capitalizeFirstLetter }}: </mat-label>
    <input matInput type="password" formControlName="passwordConfirm" />
  </mat-form-field>
  <p style="color: tomato;" *ngIf="userForm.hasError('notSame') && f.passwordConfirm">{{ 'passwordError' | translate |
    capitalizeFirstLetter }}</p>

  <br />
  <button mat-flat-button color="primary" [disabled]="userForm.invalid" (click)="createUser()" type="submit" style="
        min-height: 36px;
        border-radius: 0px;
        text-align: -webkit-center;
        display: block;
        margin-left: auto;
        margin-right: auto;
      ">
    <span *ngIf="!isLoading">Créer l'utilisateur</span>
    <mat-progress-spinner *ngIf="isLoading" style="display: block;" [diameter]="24" [mode]="'indeterminate'" color="accent">
    </mat-progress-spinner>
  </button>


</form>