import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  constructor(
    private _translate: TranslateService
  ) {
    this._translate.get("modifyPassword").subscribe();
   }
  async changeFormat(value) {
    const month = await this._translate.get("month").toPromise();
    const year = await this._translate.get("year").toPromise();
    const years = await this._translate.get("years").toPromise();
    const ageYear = Math.floor(value / 12);
    const ageMonth = value - ageYear * 12;
    if (value < 12) {
      const ageString = `${ageMonth} ${month}`;
      return ageString;
    }
    if (value === 12) {
      const ageString = `${ageYear} ${year}`;
      return ageString;
    }
    if (value < 24) {
      const ageString = `${ageYear} ${year}, ${ageMonth} ${month}`;
      return ageString;
    }
    if (value >= 24) {
      if (ageMonth === 0) {
        const ageString = `${ageYear} ${years}`;
        return ageString;
      }
      const ageString = `${ageYear} ${years}, ${ageMonth} ${month}`;
      return ageString;
    }
  }

  transform(value: any, ...args: unknown[]) {
    if (value < 0) {
      let x = '-' + this.changeFormat(value * -1);
      return x;
    } else {
      return this.changeFormat(value);
    }
  }
}
