import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { AgePipe } from './pipes/age.pipe';
import { RouterModule } from '@angular/router';
import { CardComponent } from './components/card/card.component';
import { FooterComponent } from './components/footer/footer.component';
import { TutoComponent } from './components/tuto/tuto.component';
import { TutoDossierComponent } from './components/tuto/tuto-content/tuto-dossier/tuto-dossier.component';
import { TutoEtabComponent } from './components/tuto/tuto-content/tuto-etab/tuto-etab.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CapitalizeFirstLetterPipe } from './pipes/capitalizeFirstLetter.pipe';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { TranslateServerLoader } from './loaders/translate-server.loader';
import { GraphqlService } from '../core/graphql/graphql.service';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass:TranslateServerLoader,
        deps: [HttpClient, GraphqlService],
      }
    }),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    DragDropModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatGridListModule,
    MatFormFieldModule,
    RouterModule,
    MatProgressSpinnerModule,
    NgxMaskDirective, 
    NgxMaskPipe,
  ],
  exports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorDialogComponent,
    SnackbarComponent,
    MatSnackBarModule,
    MatButtonModule,
    DragDropModule,
    MatIconModule,
    HeaderComponent,
    AgePipe,
    CapitalizeFirstLetterPipe,
    CardComponent,
    FooterComponent,
    TutoComponent,
    MatProgressSpinnerModule,
    CreateUserComponent,
    NgxMaskDirective, 
    NgxMaskPipe,
  ],
  providers: [
    AgePipe,
    provideNgxMask(),
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  declarations: [
    ErrorDialogComponent,
    SnackbarComponent,
    ConfirmationDialogComponent,
    HeaderComponent,
    AgePipe,
    CapitalizeFirstLetterPipe,
    CardComponent,
    FooterComponent,
    TutoComponent,
    TutoDossierComponent,
    TutoEtabComponent,
    CreateUserComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(
    private _translate: TranslateService,
    private _cookies: CookieService) {
    this._translate.addLangs(['fr', 'de', 'en'])
    this._translate.use(_cookies.get('lang') || 'fr');
  }
}
