<button [disabled]="isStart" mat-icon-button
  style="position: absolute; top: 50%; left: 0px; font-size: 25px; z-index: 2" (click)="slidePrev(slides)">
  <mat-icon>arrow_back_ios</mat-icon>
</button>
<button [disabled]="isEnd" mat-icon-button style="position: absolute; top: 50%; right: 0px; font-size: 25px; z-index: 2"
  (click)="slideNext(slides)">
  <mat-icon>arrow_forward_ios</mat-icon>
</button>
<ion-slides (ionSlideWillChange)="slideChanged(slides)" #slides pager="true" [options]="slideOpts" style="height: 80vh"
  mode="md" pager="md" scrollbar="md">
  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide1' | translate">
    </div>
  </ion-slide>

  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide2' | translate">
    </div>
  </ion-slide>

  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide3' | translate">
    </div>
  </ion-slide>

  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide4' | translate">
    </div>
  </ion-slide>

  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide5' | translate">
    </div>
  </ion-slide>

  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide6' | translate">
    </div>
  </ion-slide>

  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide7' | translate">
     </div>
  </ion-slide>

  <ion-slide>
    <div class="slide-container" [innerHTML]="'RECORD_TUTO.slide8' | translate">
    </div>
  </ion-slide>
</ion-slides>