import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../core.state';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { setRecord } from './record.actions';


export const RECORD_KEY = 'RECORD';

@Injectable()
export class RecordEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private _store$: Store<AppState>,
    private localStorageService: LocalStorageService,
  ) {
  }

  setRecord = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setRecord),
        tap((record ) => {
          this.localStorageService.setItem(RECORD_KEY, {...this.localStorageService.getItem(RECORD_KEY),...record});
        }
        )
      ),
    { dispatch: false }
  );
}
