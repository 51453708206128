import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { AddressModel, PaymentStatus } from 'src/app/shared/models/user.model';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class GraphqlService {

  constructor(
    private apollo: Apollo,
  ) {
  }

  emptyCache() {
    this.apollo.client.clearStore();
  }

  login(email: string, password: string) {
    return this.apollo.use("auth").mutate({
      mutation: gql`
        mutation {
            tokenAuth(email:"${email}", password:"${password}"){
                refreshExpiresIn,
                user{
                  id
                  passwordReset
                  role
                  hasPaid
                  paymentStatus
                  hasI18n
                  firstname
                  lastname
                  phone
                  email
                  subscription{
                    status
                    currentPeriodEnd
                    cancelAt
                    cancelAtPeriodEnd
                  }
                }
            }
          }
  `})
  }

  refreshToken() {
    return this.apollo.use("auth").mutate({
      mutation: gql`
      mutation { 
        refreshToken{ payload } 
      }
      `
    })
  }

  deleteToken() {
    return this.apollo.use("auth").mutate({
      mutation: gql`
        mutation {
          deleteTokenCookie {
            deleted
          }
          deleteRefreshTokenCookie {
            deleted
          }
          revokeToken{
              revoked
          }
          logout {
            status
          }
        }
  `})
  }

  resetForgottenPassword(newPassword: string, uidb64: string, token: string) {
    return this.apollo.use("auth").mutate({
      mutation: gql`
      mutation {
        resetForgottenPassword(newPassword:"${newPassword}", uidb64:"${uidb64}", token:"${token}"){
          status
        }
      }
  `})
  }

  resetPassword(password: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        resetPassword(password:"${password}"){
          passwordReset
        }
      }
  `})
  }

  changePassword(userId: string,password: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        changePassword(password:"${password}", userId:"${userId}"){
          success
        }
      }
  `})
  }

  //admin change
  changeEmail(userId: string, email: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        changeEmail(userId:"${userId}", email:"${email}"){
          email
        }
      }
  `})
  }


  changeUserPaymentStatus(userId: string, status:PaymentStatus) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        changeUserPaymentStatus(userId:"${userId}",status:"${status}"){
          success
        }
      }
  `})
  }

  toggleI18N(userId: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        toggleI18n(userId:"${userId}"){
          success
        }
      }
  `})
  }

  createUser(firstname: string, lastname: string, email: string, etab: string, street: string, number: string, zip: string, city: string, password: string, passwordReset = false) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        createUser(firstname:"${firstname}", lastname:"${lastname}", email:"${email}", etab:"${etab}", street:"${street}", number:"${number}", zip:"${zip}", city:"${city}", password:"${password}", passwordReset:${passwordReset}){
          user{
            id
          }
        }
      }
  `})
  }

  editUser(firstname: string, lastname: string, phone: string, email: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        updateUser(firstname:"${firstname}", lastname:"${lastname}", phone:"${phone}", email:"${email}"){
          success
        }
      }
  `})
  }

  changeAddress(userId: string, address: AddressModel) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        changeAddress(userId:"${userId}", street:"${address.street}", number:"${address.number}", zip:"${address.zip}", city:"${address.city}"){
          address{
            id
          }
        }
      }
  `})
  }

  archiveRecord(recordId: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        updateRecord(recordId:"${recordId}",archived:true){
          success
        }
      }
  `})
  }

  unArchiveRecord(recordId: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        updateRecord(recordId:"${recordId}",archived:false){
          success
        }
      }
  `})
  }

  updateMinMaxAge(obsId: string, minAge: number, maxAge: number) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        updateObs(obsId:"${obsId}", minAge:${minAge}, maxAge:${maxAge}){
          success
        }
      }
  `})
  }

  createRecord(date: string, gender: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        createRecord(date:"${date}", gender:"${gender}"){
          record{
            id
            recordNumber
          }
        }
      }
  `})
  }

  createObs(recordId: string, signature: string, date: string, min: number, max: number) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        createObs(recordId:"${recordId}", signature:"${signature}", date:"${date}", minAge:${min}, maxAge:${max}){
          observation{
            id
          }
        }
      }
  `})
  }

  updateObsValidated(obsId: string, validated: boolean) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        updateObs(obsId:"${obsId}", validated:${validated}){
          success
        }
      }
  `})
  }

  updateOrCreateAnswer(obsId: string, quesId: string, answerCode: string, level: number) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        updateOrCreateAnswer(obsId:"${obsId}",quesId:"${quesId}", answerCode:"${answerCode}", level:${level}){
          answer{
            id
          }
        }
      }
  `})
  }

  deleteAnswer(obsId: string, answerCode: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        deleteAnswer(obsId:"${obsId}", answerCode:"${answerCode}"){
          success
        }
      }
  `})
  }

  deleteObs(obsId: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        deleteObs(obsId:"${obsId}"){
          success
        }
      }
  `})
  }

  deleteRecord(recordId: string) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        deleteRecord(recordId:"${recordId}"){
          success
        }
      }
  `})
  }

  hasRecords() {
    return this.apollo.query({
      query: gql`
      {
        hasRecords
        hasArchives
      }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  getCurrentUser() {
    return this.apollo.query({
      query: gql`
      {
        currentUser{
          id
          firstname
          lastname
          phone
          role
          dateCreated
          email
          passwordReset
          hasPaid
          paymentStatus
          hasI18n
          etab
          subscription {
            subscriptionId
            status
            currentPeriodEnd
            cancelAt
            cancelAtPeriodEnd
          }
          address {
            street
            number
            zip
            city
          }
        }
      }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  getUserForAdmin(pk:number) {
    return this.apollo.query({
      query: gql`
      {
        user(pk:${pk}){
          id
          hasPaid
          paymentStatus
          hasI18n
          firstname
          lastname
          email
          dateCreated
          etab
          subscription {
            status
          }
          address {
            street
            number
            zip
            city
          }
        }
      }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  getUsers() {
    return this.apollo.query({
      query: gql`
      {
        users{
          id
          paymentStatus
          firstname
          lastname
          email
          dateCreated
        }
      }
      `
    })
  }

  getAllRecordsCount() {
    return this.apollo.query({
      query: gql`
      {
        allRecordsCount {
          email
          recordCount
          obsCount {
            recordNumber
            count
          }
        }
      }
      
      `
    })
  }

  findRecords(searchValue: string, archived: boolean) {
    return this.apollo.query({
      query: gql`
      {
        findRecords(searchValue: "${searchValue}", archived: ${archived}){
          id
          recordNumber
        }
      }
      `
    })
  }

  getRecordInfo(recordId: string) {
    return this.apollo.query({
      query: gql`
      {
        recordInfo(recordId: "${recordId}"){
            record{
              id
              birthdate
              recordNumber
              gender
            }
            pendingObsId
            validatedObsIds    
        }
      }
      `
    })
  }

  getAllRecords(userId: string) {
    return this.apollo.query({
      query: gql`
      {
        allRecords(userId: "${userId}"){
            id
            recordNumber
        }
      }
      `
    })
  }

  getObs(obsId: string) {
    return this.apollo.query({
      query: gql`
      {
        observation(obsId: "${obsId}"){
            id
            date
            validated    
            signature
            minAge
            maxAge
        }
      }
      `, fetchPolicy: 'network-only'
    })
  }


  getFinishedObservations(ids: string[]) {
    return this.apollo.query({
      query: gql`
      {
        bulkObservations(obsIds: ${JSON.stringify(ids)}){
            id
            date
        }
      }
      `
    })
  }

  getQuestions(obs_id: string, age_at_obs: number, min_age: number, max_age: number, selected_cat_values: string[], question_filter: string, answer_filter: string, learning_level_filter: number[]) {
    return this.apollo.query({
      query: gql`
      {
        observationQuestions(obsId:"${obs_id}",ageAtObs:${age_at_obs},minAge:${min_age},maxAge:${max_age},selectedCatValues: ${JSON.stringify(selected_cat_values)}, questionFilter:"${question_filter}", answerFilter:"${answer_filter}",learningLevelFilter: ${JSON.stringify(learning_level_filter)}){
            id
            category1NameLong
            direction
            startMonth
            endMonth
            description
            category1Rk
            category1Name
            answerCode
            category2Rk
        }
      }
      `,fetchPolicy: 'no-cache'
    })
  }

  getSyntheseData(recordId: string) {
    return this.apollo.query({
      query: gql`
      {
        assimilationData(recordId: "${recordId}"){
          x
          y
        }
      }
      `,
      fetchPolicy: 'network-only'
    })
  }
  getUserStats() {
    return this.apollo.query({
      query: gql`
      {
        recordCount {
          recordNumber
          count
        }
        recordByYearCount {
          year
          percentage
        }
        observationsByDateCount {
          date
          count
        }
      }      
      `,
      fetchPolicy: 'network-only'
    })
  }
  getObsAnswers(obsId: string) {
    return this.apollo.query({
      query: gql`
      {
        observationAnswers(obsId: "${obsId}"){
            id
            date
            level    
            answerCode
            question{
              startMonth
              endMonth
            }
        }
      }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  getRecordAnswers(recordId: string) {
    return this.apollo.query({
      query: gql`
      {
        recordAnswers(recordId: "${recordId}"){
            answerCode
            level
            observation{
              date
            }
            question{
              direction
              endMonth
            }
        }
      }
      `,
      fetchPolicy: 'no-cache'
    })
  }

  getLanguage(lang: string) {
    return this.apollo.query({
      query: gql`
      {
        language(lang: "${lang}"){
            lang
            translations
        }
      }
      `
    })
  }

  getAnswersHistory(recordId: string, answerCode: string) {
    return this.apollo.query({
      query: gql`
      {
        answersHistory(recordId: "${recordId}",answerCode: "${answerCode}"){
            date
            level   
            observation{
              signature
            } 
        }
      }
      `
    })
  }



  //////////////////////////////////////
  //
  //             BILLING              //
  //
  //////////////////////////////////////



  subscribeToPlan(pmID, plan) {
    return this.apollo.mutate({
      mutation: gql`
      mutation {
        subscribe(paymentMethodId:"${pmID}", plan:"${plan}"){
          subscription {
            subscriptionId
            status
            clientSecret
            currentPeriodEnd
            cancelAt
            cancelAtPeriodEnd
          }
        }
      }
      `
    })
  }


  toggleCancelSubscription(subId, cancel) {
    return this.apollo.mutate({
      mutation: gql`
    mutation {
      setCancelAtPeriodEnd(subscriptionId:"${subId}", cancel: ${cancel}){
        success
      }
    }
    `
    })
  }
}