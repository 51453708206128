import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './core.state';
import { AuthEffects } from './auth/auth.effects';
import { UserEffects } from './user/user.effects';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { CookieModule } from 'ngx-cookie';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './http-interceptors/request.interceptor';
import { RecordEffects } from './record/record.effects';


export function createDefaultApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({ uri: environment.graphql }),
    cache: new InMemoryCache()
  };
}

export function createNamedApollo(httpLink: HttpLink): Record<string, ApolloClientOptions<any>> {
  return {
    auth: {
      name: 'auth',
      link: httpLink.create({ uri: environment.auth }),
      cache: new InMemoryCache()
    },
  };
}

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, { metaReducers }),
    CookieModule.withOptions(),
    ApolloModule,
    EffectsModule.forRoot(
      [
        AuthEffects,
        UserEffects,
        RecordEffects
      ]
    ),
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    deps: [HttpLink],
    useFactory: createDefaultApollo
  },
  {
    provide: APOLLO_NAMED_OPTIONS,
    deps: [HttpLink],
    useFactory: createNamedApollo
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptor,
    multi: true
  }
  ],
})
export class CoreModule { }
